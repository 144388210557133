"use strict";
// noinspection SpellCheckingInspection
Object.defineProperty(exports, "__esModule", { value: true });
exports.isWhichPath = isWhichPath;
exports.isThereErrorMessage = isThereErrorMessage;
exports.intervalCheckForErrorMessage = intervalCheckForErrorMessage;
exports.populateModel = populateModel;
const exceptions_1 = require("./exceptions");
const utils_1 = require("./utils");
function isWhichPath() {
    const { pathname } = window.location;
    console.log(pathname);
    // https://es.wallapop.com/login?redirectUrl=https%3A%2F%2Fes.wallapop.com%2Fapp%2Fcatalog%2Fpublished
    const isEditPage = pathname.indexOf('edit') !== -1;
    const isListPage = pathname.indexOf('published') !== -1;
    const isLoginPage = pathname.indexOf('login') !== -1;
    return {
        isEditPage,
        isListPage,
        isLoginPage,
    };
}
function isThereErrorMessage() {
    // `¡Ojo!
    // Revisa los campos en rojo`
    const messageToFind = 'Revisa los campos en rojo';
    return (0, utils_1.textIsPresent)(messageToFind);
}
/**
 * It will check periodically every 1 second during the `numIntervals`
 * If there is an error it will throw
 * @throws InvalidInputException
 */
async function intervalCheckForErrorMessage(numIntervals) {
    for (let i = 0; i < numIntervals; i++) {
        if (isThereErrorMessage()) {
            throw new exceptions_1.InvalidInputException('invalid input in form');
        }
        else {
            await new Promise((resolve) => setTimeout(resolve, 1000));
        }
    }
}
function simulateKeyPress(input, key) {
    const keydownEvent = new KeyboardEvent('keydown', { key });
    const keypressEvent = new KeyboardEvent('keypress', { key });
    const keyupEvent = new KeyboardEvent('keyup', { key });
    input.dispatchEvent(keydownEvent);
    input.value += key;
    input.dispatchEvent(keypressEvent);
    input.dispatchEvent(keyupEvent);
}
/**
 * populates the form
 * @deprecated
 */
function populateModel(text) {
    const input = document.querySelector('#model');
    if (!input) {
        console.error("Element '#model' was not found.");
        return;
    }
    // Clear the input
    input.value = "";
    // simulate key presses
    for (const character of text) {
        simulateKeyPress(input, character);
    }
    // Dispatch input and change events at the end
    const inputEvent = new Event('input');
    const changeEvent = new Event('change');
    input.dispatchEvent(inputEvent);
    input.dispatchEvent(changeEvent);
}
