"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addHashChangeListener = addHashChangeListener;
exports.addNavigateListener = addNavigateListener;
const utils_1 = require("./utils");
function addHashChangeListener(fn) {
    window.addEventListener('hashchange', async function () {
        console.log('hash changed!');
        await fn();
    });
}
async function addNavigateListener(lastRenewedAttemptManager) {
    // Add a timeout to try to avoid the rush condition that will detect the initial page load as a navigation.
    await (0, utils_1.waitForTimeout)(1000);
    // The null-safe operator is for the tests to not fail
    // @ts-ignore
    navigation === null || navigation === void 0 ? void 0 : navigation.addEventListener("navigate", async (e) => {
        console.log("navigated from ", document.location);
        console.log(JSON.stringify(document.location));
        if (document.location.href == e.destination.url) {
            // TODO: comment or remove log
            console.log('navigation false alarm');
            return;
        }
        console.log(`navigate ->`, e.destination.url);
        if (await lastRenewedAttemptManager.isLastRenewedAttemptRecent()) {
            console.debug('navigation happened, but last run attempt is recent -> not running bot again');
            return;
        }
        // TODO: reenable
        // console.log('trying to run bot... (as last renew attempt is not recent)');
        //
        // const bot = createBot();
        // await bot.runBot();
    });
}
